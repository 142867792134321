import React, {FC} from "react"
import styled from "@emotion/styled";
import topImage from "../images/outside.webp"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
  color: white;
  min-height: 500px;
`;

const InfoContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  * {
    margin-bottom: 20px;
  }
`;

const TextContainer = styled.div`
  background-color: rgba(0, 127, 255, 0.5);
  padding: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1400px;
  background: right;
  background-image: url(${topImage});
  background-repeat: no-repeat;
  background-color: #007FFF;
  background-size: auto 100%;
`;

const TopContent: FC = () => {
  return <Container>
    <ContentContainer>
      <InfoContainer>
        <TextContainer>
          <h1>Holiday Cottage To Let In The Beautiful Peak District</h1>
          <p><b>Hills Farm Holiday Cottage Is Set In 38 Acres Of North East Derbyshire Countryside With Fabulous Views.</b></p>
          <p><b>Close to Chatsworth, Haddon Hall, Hardwick Hall, Renishaw Hall</b></p>
          <p><b>Near to the amenities of Sheffield and Chesterfield and the delights of the Peak District</b></p>
          <p><b>For the best rates and availability please contact us direct. No booking fee</b></p> 
        </TextContainer>
      </InfoContainer>
    </ContentContainer>
  </Container>
}

export default TopContent;