import React, {FC} from "react"
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
  color: white;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 12px;
  background-color: #007FFF;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Consultation: FC = () => {
  return <Container>
    <ContentContainer>
      <h2>Please Get In Touch For Further Information</h2>
      <p><b>sjpawson@btinternet.com</b></p>
      <p><b>07979 605 379</b></p>
    </ContentContainer>
  </Container>
}

export default Consultation;