import BathroomLarge from "./Bathroom Large.webp"
import BedroomTwo from "./Bedroom 2.webp"
import BedroomS2 from "./Bedroom S2.webp"
import BedroomView from "./Bedroom View.webp"
import Bedroom from "./Bedroom.webp"
import Entrance from "./Entrance.webp"
import Kitchen from "./Kitchen.webp"
import LivingRoom from "./Living Room.webp"
import LR from "./LR.webp"
import Outside from "./Outside.webp"
import ViewOutside from "./View Outside.webp"

export default [
    {image: LivingRoom, description: 'Living Room'},
    {image: ViewOutside, description: 'View from the Living Room'},
    {image: Kitchen, description: 'Kitchen'},
    {image: BedroomTwo, description: 'Master Bedroom'},
    {image: BathroomLarge, description: 'Large Bathroom'},
    {image: BedroomView, description: 'Master Bedroom View'},
    {image: Entrance, description: 'Entrance'},
    {image: Outside, description: 'House from the Outside'},
    {image: Bedroom, description: 'Master Bedroom'},
    {image: BedroomS2, description: 'Small Bedroom'},
    {image: LR, description: 'Living Room'},
]