import React, {FC, useEffect, useLayoutEffect} from "react"
import styled from "@emotion/styled";

import images from "../../images/slideshow"

const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
`;

const Image = styled.img`
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
`;

const SlideShow: FC = () => {

    const [image, setImage] = React.useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setImage((image + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [image]);

    return <Container>
        <Image src={images[image].image} alt={images[image].description} />
    </Container>
}

export default SlideShow;