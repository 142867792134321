import React, {FC} from "react"
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
  padding: 0 12px;
`;

const ListItem = styled.li`
  margin-bottom: 12px;
`;


const ProjectLifecycle: FC = () => {
  return <Container>
    <div>
      <h1>Info</h1>
      <ul>
        <ListItem><b>Welcome Pack</b></ListItem>
        <ListItem><b>Family Friendly</b></ListItem>
        <ListItem><b>Off Road Parking</b></ListItem>
        <ListItem><b>Internet Access, Central Heating, Electric and Towels Included</b></ListItem>
        <ListItem><b>Electric Hob, Oven, Microwave, Fridge, Freezer and Washing Machine</b></ListItem>
        <ListItem><b>Two Bedrooms Which Can Be Set Up As Twin Or Double Rooms By Request At The Time Of Booking</b></ListItem>
        <ListItem><b>Downstairs Bathroom and En-Suite Shower Room in the Master Bedroom</b></ListItem>
        <ListItem><b>Enclosed Garden And Patio With Garden Furniture</b></ListItem>
        <ListItem><b>TV, DVD, CD and Radio</b></ListItem>
        <ListItem><b>Real Ale Pub Serving Good Food and Shop Nearby</b></ListItem>
        <ListItem><b>This Property Will Be Decorated For Christmas</b></ListItem>
        <ListItem><b>Travel Cot and Highchair Available</b></ListItem>
        <ListItem><b>Arrival: 15:00 Onwards</b></ListItem>
        <ListItem><b>Departure: Before 10:00, Unless Otherwise Stated In Your Arrival Details</b></ListItem>
        <ListItem><b>Sorry No Pets Allowed, Working Farm</b></ListItem>
      </ul>
    </div>
  </Container>
}

export default ProjectLifecycle;