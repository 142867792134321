import React, {FC} from "react"
import styled from "@emotion/styled";
import SlideShow from "./SlideShow";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 50px;
  text-align: center;
`;

const SlidesContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 84px;
  color: #007FFF;

  @media screen and (max-width: 360px) {
    font-size: 53px;
  }

  @media screen and (max-width: 320px) {
    font-size: 33px;
  }

`;



const CaseStudies: FC = () => {

  return <Container>
    <SlidesContainer>
      <SlideShow />
    </SlidesContainer>
  </Container>
}

export default CaseStudies;